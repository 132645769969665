"use client";

import CallAPI, { CallAPIURL } from "@/lib/CallAPI";

// React
import React, { createContext, useEffect, useState } from "react";

// JS Cookie
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Sentry
import * as Sentry from "@sentry/nextjs";
import { useTranslations } from "next-intl";
import { Employee, User } from "@/utils/types/common";
import { errorHandler } from "@/utils/errorHandler";

export type UserContextType = {
  fullName: string;
  idUser: number;
  idPerson: number;
  idEmployee: number;
  user: User;
  location: string;
  deviceFingerprint: string;
  fingerprintBrowser: string;
  browserData: string;
  setLocation: (location: string) => void;
  setDeviceFingerprint: (deviceFingerprint: string) => void;
  setFingerprintBrowser: (fingerprintBrowser: string) => void;
  setBrowserData: (browserData: string) => void;
  setUserIdFromToken: (token: string) => void;
  getUser: () => void;
  addNotes: () => string;
  isLoading: boolean;
};

const UserContext = createContext<UserContextType>({
  fullName: "",
  idUser: 0,
  idPerson: 0,
  idEmployee: -1,
  user: {} as User,
  location: "",
  deviceFingerprint: "",
  fingerprintBrowser: "",
  browserData: "",
  setLocation: () => {},
  setDeviceFingerprint: () => {},
  setFingerprintBrowser: () => {},
  setBrowserData: () => {},
  setUserIdFromToken: () => {},
  getUser: () => {},
  addNotes: () => "",
  isLoading: true,
});

const UserContextProvider = ({ children }: { children: any }) => {
  const t = useTranslations("QuickPunch");

  const [user, setUser] = useState<User>({} as User);
  const [location, setLocation] = useState("");
  const [deviceFingerprint, setDeviceFingerprint] = useState("");
  const [browserData, setBrowserData] = useState("");
  const [fingerprintBrowser, setFingerprintBrowser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [idUser, setIdUser] = useState(0);
  const [idPerson, setIdPerson] = useState(0);
  const [employee, setEmployee] = useState<Employee>({ id: -1 } as Employee);

  const addNotes = () => {
    let note = " ";
    if (location) {
      note += `${t("Location")} (lat,long) : ${location} <br />`;
    }
    if (deviceFingerprint) {
      note += `${t("DeviceFingerprint")} : ${deviceFingerprint} <br />`;
    }
    if (fingerprintBrowser) {
      note += `${t("BrowserFingerprint")} : ${fingerprintBrowser} <br />`;
    }
    if (browserData) {
      note += `${t("BrowserData")} : ${browserData}`;
    }
    return note;
  };

  const getUser = async () => {
    setIsLoading(true);
    try {
      const res = await CallAPI("GET", CallAPIURL.getLoggedUserInfos);
      const data = res.data.response;
      if (!res.data.error) {
        setUser(data);
        setIdPerson(data.person.id);
        const emp = await CallAPI(
          "GET",
          CallAPIURL.getEmpDropdown,
          JSON.stringify({ person: data.person.id })
        );
        if (emp.data.response["hydra:member"].length > 0) {
          setEmployee(emp.data.response["hydra:member"][0]);
        } else {
          setEmployee({ id: -2 } as Employee);
        }
      }
    } catch (err: any) {
      errorHandler(err, `Error fetching logged user infos : `);
    }

    setIsLoading(false);
  };

  const setUserIdFromToken = (token: string) => {
    const decodedJwt: { user: { id: number } } = jwtDecode(token || "");
    setIdUser(decodedJwt.user.id);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = cookie.get("currentUser");
      if (token) {
        setUserIdFromToken(token);
      }
    }

    if (!user?.person) {
      getUser();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        fullName: user?.username || "",
        idUser,
        idPerson,
        idEmployee: employee?.id,
        user,
        location,
        deviceFingerprint,
        fingerprintBrowser,
        browserData,
        setLocation,
        setDeviceFingerprint,
        setFingerprintBrowser,
        setBrowserData,
        setUserIdFromToken,
        getUser,
        addNotes,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
