import { TenantWhitelist, TenantIgnorelist } from "./TenantList";


//Checks the url for tenant
//If url not long enough or takes localhost, uses environnement variable instead
export default function GetTenantBackend(request: Request){
    var urlSplit: Array<string> | undefined;
    urlSplit = request.url.split("/")[2].split(".");
    
    return GetTenantCore(urlSplit);
  }
  
export function GetTenantCore(urlSplit?:Array<string>|undefined){
    var x = 0;
    var tenant: string | undefined = undefined;
  
    if (process.env.NEXT_PUBLIC_TENANT != "" || process.env.NEXT_PUBLIC_TENANT) {
      tenant = process.env.NEXT_PUBLIC_TENANT;
    }
  
    if (typeof urlSplit !== "undefined") {
      if (TenantIgnorelist.indexOf(urlSplit[x]) !== -1) {
        x++;
      }
      if (TenantWhitelist.indexOf(urlSplit[x]) !== -1) {
        tenant = urlSplit[x];
      }
    }
  
    return tenant;
  }