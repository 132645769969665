export const TenantWhitelist: Array<string> = [
  "demo-ecs",
  "demo",
  "staging",
  "repere",
  "picard",
  "phpc",
  "excavationperreault",
  "aa"
];

export const TenantIgnorelist: Array<string> = [
  "www",
];