"use client";

// React
import React, { createContext, useContext, useEffect, useState } from "react";

// Sentry
import * as Sentry from "@sentry/nextjs";

// Contexts
import { UserContext, UserContextType } from "./UserContext";
import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Utils
import CallAPI, { CallAPIURL } from "@/lib/CallAPI";
import { getFromCache, storeToCache } from "@/lib/GetCache";
import { DecodedJwt } from "./KVSContext";
import GetTenant from "@/lib/GetTenant";
import { errorHandler } from "@/utils/errorHandler";

export type RolesContextType = {
  arrayRoles: any;
  getUserRoles: () => void;
  idUser?: string;
};

const RolesContext = createContext<RolesContextType>({
  arrayRoles: [],
  getUserRoles: () => {},
  idUser: "",
});

const RolesContextProvider = ({ children }: { children: any }) => {
  const [arrayRoles, setArrayRoles] = useState();
  const [idUser, setIdUser] = useState("");

  const { user } = useContext<UserContextType>(UserContext);

  const getUserRoles = async () => {
    try {
      let idUser = user.id;
      if (!idUser) {
        const jwtUser = cookie.get("currentUser");
        if (jwtUser) {
          try {
            const decodedJwt: DecodedJwt = jwtDecode(jwtUser);
            idUser = decodedJwt.user.id;
          } catch (error: any) {
            errorHandler(error, "Error decoding jwt: ");
            return;
          }
        } else {
          Sentry.captureException("No user id found");
          return;
        }
      }
      setIdUser(idUser.toString());

      const cachedRoles = await getFromCache(
        GetTenant() || "tenant",
        idUser.toString(),
        "userRoles"
      );

      if (cachedRoles) {
        setArrayRoles(cachedRoles);
        return cachedRoles;
      }

      const { data } = await CallAPI(
        "GET",
        CallAPIURL.getUserRoles,
        JSON.stringify({
          page: "1",
          itemsPerPage: "30",
        })
      );

      if (!data.response.roles) {
        console.error("Error fetching user roles:", data);
        Sentry.captureException(data);
        return;
      }

      setArrayRoles(data.response.roles);

      const ttl = new Date();
      ttl.setHours(ttl.getHours() + 24);

      await storeToCache(
        GetTenant() || "tenant",
        idUser.toString(),
        "userRoles",
        data.response.roles,
        ttl
      );

      if (data.response.roles.length === 0) {
        console.error("No roles found for user:", user);
        Sentry.captureException(
          `No roles found for user, tenant: ${
            GetTenant() || "tenant"
          } \n idUser: ${idUser}\n roles: ${data.response.roles}`
        );
      }
      return data.response.roles;
    } catch (error: any) {
      errorHandler(error, `Error fetching user roles ${user.id}: `);
    }
  };

  useEffect(() => {
    if (JSON.stringify(user) !== "{}") getUserRoles();
  }, [user]);

  return (
    <RolesContext.Provider value={{ arrayRoles, getUserRoles, idUser }}>
      {children}
    </RolesContext.Provider>
  );
};

export { RolesContext, RolesContextProvider };
